import { Head } from '@inertiajs/react'
import { ReactElement } from 'react'

export default function Index (): ReactElement {
  return (
    <>
      <Head title='Mes contributions' />
      <div />
    </>
  )
}
